import { render } from "./AnnualProductionComponent.vue?vue&type=template&id=320a7023&scoped=true"
import script from "./AnnualProductionComponent.vue?vue&type=script&lang=ts"
export * from "./AnnualProductionComponent.vue?vue&type=script&lang=ts"

import "./AnnualProductionComponent.vue?vue&type=style&index=0&id=320a7023&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-320a7023"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QCard,QCardSection,QForm,QSelect,QItem,QItemSection,QCheckbox,QItemLabel,QInput,QIcon,QTooltip});
